.divider:after,
.divider:before {
  content: "";
  flex: 1;
  height: 1px;
  background: #eee;
}
.h-custom {
  height: calc(100% - 73px);
}
@media (max-width: 450px) {
  .h-custom {
    height: 100%;
  }
}
.memberLogin p,.memberLogin path{
    color: black!important;
}
.modal label, .modal h1{
  color: black!important;
}
#datatable input{
  color: black!important;
}
tbody, td, tfoot, th, thead, tr {
  border-color: white;
  border-style: solid;
  border-width: .5px;
}
table.dataTable.cell-border tbody tr th:first-child, table.dataTable.cell-border tbody tr td:first-child {
  border-left: 1px solid black;
}
table.dataTable.cell-border tbody th, table.dataTable.cell-border tbody td {
  border-top: 1px solid white;
  border-right: 1px solid black;
}
#example_filter{
  margin-bottom: 8px;
}
table.dataTable thead th, table.dataTable thead td {
  padding: 10px;
  border-bottom: 1px solid black;
}
#dataTable div,#dataTable nav,#dataTable header{
  display: flex;
  background-color: white;
  color: black;
  
}
#dataTable button{
  background-color: white;
  color: black;
  fill:black
}